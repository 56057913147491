<template>
  <div id="formEntryList" class="form-list">
    <div class="list-entries" :class="{ 'has-sections': sectionCount > 0 }">
      <div v-for="(entry, index) in schema" :id="entry.key" :ref="entry.key" :key="entry.key">
        <div
          class="py-5 text-right px-5 pt-6"
          v-if="!submission && entry.ui.el == 'section' && entry.section - 1 == activeSection"
        >
          <v-btn
            class="mr-3"
            @click="
              activeSection = activeSection - 1;
              goToTop();
            "
            v-if="activeSection != 1"
          >
            {{ $t("t_back") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="
              validateForm();
              goToTop();
            "
          >
            {{ $t("t_next") }}
          </v-btn>
        </div>

        <div v-show="submission || entry.section == undefined || entry.section == activeSection">
          <div>
            <div v-if="entry.ui.el == 'section'" class="section-header px-6 py-2 mt-2 font-weight-bold">
              {{ $t("t_section") }} {{ entry.section }} {{ $t("t_of") }} {{ sectionCount }}
            </div>
            <div v-if="entry.ui.el == 'section' && !entry.value" class="section-header-border"></div>
            <div
              class="entry-section"
              :class="[entry.ui.el, entry.answer ? entry.answer.ui.el : '', getLang]"
              v-if="!(entry.ui.el == 'section' && !entry.content.title && !entry.content.description)"
            >
              <component :is="component(entry, entry.key, entry.section)"></component>
              <div v-if="entry.answer">
                <component :is="component(entry.answer, entry.key, entry.section)"></component>
              </div>
              <div v-else-if="entry.question" class="answer-placeholder"></div>
            </div>
          </div>

          <v-card-actions
            class="py-5 px-5 pt-6"
            v-if="
              !submission &&
              ((index + 1 == entryCount && entry.section == sectionCount) || (index + 1 == entryCount && !sectionCount))
            "
          >
            <v-btn class="d-none d-sm-inline" @click="$router.go(-1)">{{ $t("t_cancel") }}</v-btn>
            <v-spacer></v-spacer>
            <div>
              <v-btn
                v-if="activeSection > 1"
                class="mr-3"
                @click="
                  activeSection = activeSection - 1;
                  goToTop();
                "
              >
                {{ $t("t_back") }}
              </v-btn>
              <v-btn v-if="isAssignment" class="mr-3" @click="validateForm(false)">{{ $t("t_save") }}</v-btn>
              <v-btn v-if="isAssignment" color="primary" @click="validateForm(true)">{{
                $t("t_save_and_submit")
              }}</v-btn>
              <v-btn v-else color="primary" @click="validateForm(true)">{{ $t("t_submit") }}</v-btn>
            </div>
          </v-card-actions>
        </div>
      </div>
      <v-alert
        ref="alert-check-required"
        class="mt-1 mx-8 float-right alert-check-required"
        max-height="38px"
        dense
        type="error"
        outlined
      >
        {{ $t("c_formviewer.t_check_required_fields") }}
      </v-alert>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { AssetService, FormService } from "@/services";
import AppConfirmDialog from "@/components/AppConfirmDialog.vue";
import vueSignature from "vue-signature";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import idb from "@/api/idb";

import {
  VCheckbox,
  VContainer,
  VDatePicker,
  VFileInput,
  VSelect,
  VMenu,
  VSlider,
  VRadioGroup,
  VRadio,
  VTimePicker,
  VTextarea,
  VTextField,
  VDialog,
  VCard,
  VBtn,
  VImg,
  VSpacer,
  VIcon,
  VAlert,
} from "vuetify/lib";

export default {
  name: "form-viewer",
  components: {
    AppConfirmDialog,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    formId: {
      type: Number,
      default: null,
    },
    submissionId: {
      type: Number,
      default: null,
    },
    schema: {
      type: Array,
      default: () => [],
    },
    preview: {
      type: Boolean,
      default: false,
    },
    submission: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    isAssignment: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      activeSection: 1,
      steps: [],
      formValid: true,
    };
  },
  computed: {
    sectionCount() {
      return Math.max(...this.schema.map((e) => e.section));
    },
    entryCount() {
      return this.schema.length;
    },
    getLang() {
      return this.$store.state?.settings?.lang?.toLowerCase();
    },
  },
  methods: {
    validateForm(submit) {
      this.$emit("validate", submit, this.isAssignment, this.activeSection);
    },

    goToTop() {
      document.getElementById("formEntryList").scrollIntoView();
    },

    component(entry, key, section) {
      const submission = this.submission;
      const preview = this.preview;
      const form = this.form;
      const formId = this.formId;
      const submissionId = this.submissionId;
      const activeSection = this.activeSection;
      const rules = [];
      const fontStyle = (style) => {
        return {
          [`form-text-${style?.fontSize || 3}`]: true,
          "text-decoration-underline": style?.underline,
          "font-weight-bold": style?.bold,
          "font-italic": style?.italic,
          "text-center": style?.center,
          "form-text-center": style?.center,
        };
      };

      const checkValidation = (item) => {
        if (!item.entry.required) return;
        if (item.entry.ui.el == "checkbox") {
          if (item.entry.value?.some((v) => v == true)) {
            item.$refs.checkbox?.closest(".entry-section").classList.remove("show-required");
            if (document.getElementsByClassName("show-required")?.length == 0) {
              item.$parent.$refs["alert-check-required"]?.$el.classList.remove("show-alert");
            }
          } else {
            item.$refs.checkbox?.closest(".entry-section").classList.add("show-required");
            item.$parent.$refs["alert-check-required"]?.$el.classList.add("show-alert");
          }
        } else if (item.entry.value !== null && item.entry.value !== "") {
          item.$refs.input?.$el.closest(".entry-section").classList.remove("show-required");
          if (document.getElementsByClassName("show-required")?.length == 0) {
            item.$parent.$refs["alert-check-required"]?.$el.classList.remove("show-alert");
          }
        } else {
          item.$refs.input?.$el.closest(".entry-section").classList.add("show-required");
          item.$parent.$refs["alert-check-required"]?.$el.classList.add("show-alert");
        }
      };

      switch (entry.ui.el) {
        case "text":
          return Vue.extend({
            template: `<div :class="classes()" style="white-space: pre-line;">{{entry.value}}</div>`,
            data() {
              return {
                entry,
              };
            },
            methods: {
              classes() {
                return {
                  "font-weight-medium": true,
                  ...fontStyle(this.entry.ui.style),
                };
              },
            },
          });

        case "content":
          return Vue.extend({
            template: `<div class="html-wrap"><div v-html="entry.value"></div></div>`,
            data() {
              return {
                entry,
              };
            },
          });

        case "select":
          return Vue.extend({
            template: `
          <div class="answer-wrap select" :class="{'required':entry.required && !submission}">
            <div v-if="!submission">
              <span class="flag" v-if="entry.required">* {{$t("t_required")}}</span>
                <select @input="checkVal" ref="input" v-model="entry.value" class="mt-5 select-dropdown">
                  <option class="option-default" :value="null">-- {{$t('t_select')}} --</option>
                  <option class="option" v-for="option in entry.ui.items">
                  {{ option }}
                  </option>
                </select>
            </div>
            <div v-else class="mt-2 ml-4">{{entry.value}}</div>
          </div>
        `,
            components: { VSelect, VContainer },
            data() {
              return {
                entry,
                key,
                submission,
              };
            },
            methods: {
              checkVal(e) {
                if (!this.entry.required) return;
                if (e.currentTarget.value) {
                  e.currentTarget.closest(".entry-section").classList.remove("show-required");
                  if (document.getElementsByClassName("show-required")?.length == 0) {
                    this.$parent.$refs["alert-check-required"]?.$el.classList.remove("show-alert");
                  }
                } else {
                  e.currentTarget.closest(".entry-section").classList.add("show-required");
                  this.$parent.$refs["alert-check-required"]?.$el.classList.add("show-alert");
                }
              },
            },
          });

        case "input":
          return Vue.extend({
            template: `
          <div class="answer-wrap" :class="{'required':entry.required && !submission}"><span class="flag" v-if="!submission && entry.required">* {{$t("t_required")}}</span>
            <v-text-field @input="checkVal" ref="input" v-if="!submission" v-bind="opts" v-model.trim="entry.value" class="mt-5"></v-text-field>
            <div v-else class="mt-2 ml-4">{{entry.value}}</div>
          </div>
          `,
            components: { VTextField },
            data() {
              return {
                entry,
                section,
                activeSection,
                preview,
                submission,
                opts: {
                  filled: true,
                  placeholder: this.$t("c_formviewer.t_answer"),
                  outlined: true,
                },
              };
            },
            methods: {
              checkVal() {
                checkValidation(this);
              },
            },
          });

        case "slider":
          return Vue.extend({
            template: `
          <v-container>
          <div class="answer-wrap" :class="{'required':entry.required}">
            <span class="flag" v-if="!submission && entry.required">* {{$t("t_required")}}</span>
            <v-slider @input="checkVal" ref="input" class="mt-6" v-bind="[opts, entry.ui.sliderOpts]" :thumb-label="false" v-model="entry.value" :vertical="entry.ui.sliderOpts.verticalMobile && $vuetify.breakpoint.mobile" :class="$vuetify.breakpoint.mobile ? 'height-' + entry.ui.sliderOpts.max : ''"></v-slider>
          </div>
          </v-container>
          `,
            components: { VSlider, VContainer },
            data() {
              return {
                entry,
                submission,
                opts: {
                  readonly: preview,
                  disabled: submission,
                  class: "my-0",
                },
                tickLabels: [],
                verticalMobile: true,
              };
            },
            watch: {
              "entry.ui.sliderOpts.tickLabels"(n) {
                if (n && n.length) {
                  this.tickLabels = n;
                }
              },
              "entry.ui.sliderOpts.showTickLabels"(n) {
                if (!n) {
                  this.entry.ui.sliderOpts.tickLabels = [];
                } else {
                  this.entry.ui.sliderOpts.tickLabels = this.tickLabels;
                }
              },
            },
            methods: {
              checkVal() {
                checkValidation(this);
              },
            },
            mounted() {
              this.tickLabels = this.entry.ui.sliderOpts?.tickLabels;
              this.verticalMobile = this.entry.ui.sliderOpts?.verticalMobile;
            },
          });

        case "radio":
          return Vue.extend({
            template: `
          <v-container>
          <div class="answer-wrap" :class="{'required':entry.required}">
            <span class="flag" v-if="!submission && entry.required">* {{$t("t_required")}}</span>
            <v-radio-group ref="input" v-bind="opts" v-model="entry.value" @change="checkVal">
                <v-radio class="option" v-for="(choice, i) in entry.ui.items" :value="i" :key="i">
                    <template v-slot:label>
                        <input :value="choice" @focus="$event.target.select()" readonly="true">
                    </template>
                </v-radio>
            </v-radio-group>
          </div>
          </v-container>
          `,
            components: { VRadioGroup, VRadio, VContainer },
            data() {
              return {
                entry,
                submission,
                opts: {
                  disabled: submission,
                  class: "my-0",
                  row: entry.options?.row,
                },
              };
            },
            methods: {
              update(e, i) {
                const items = [...this.entry.ui.items];
                items[i] = e.currentTarget.value?.trim();
                this.entry.ui.items = [...items];
              },
              checkVal() {
                checkValidation(this);
              },
            },
          });

        case "checkbox":
          return Vue.extend({
            template: `
          <v-container>
          <div class="answer-wrap" :class="{'required':entry.required}">
            <span class="flag" v-if="!submission && entry.required">* {{$t("t_required")}}</span>
            <v-container class="pa-0 pb-2" :style="row ? 'display: flex; flex-wrap: wrap' : ''" ref="checkbox">
                <v-checkbox
                    v-for="(choice, i) in entry.ui.items"
                    v-bind="opts" :key="i"
                    v-model="entry.value[i]"
                    class="option"
                    :class="row ? 'mr-4 my-0' : 'ma-0'"
                    @change="checkVal"
                >
                    <template v-slot:label>
                        <input class="input-viewer" readonly="true" :value="choice" style="width: 100%" @focus="$event.target.select()">
                    </template>
                </v-checkbox>
            </v-container>
          </div>
          </v-container>
          `,
            components: { VContainer, VCheckbox },
            data() {
              return {
                entry,
                submission,
                opts: {
                  readonly: preview,
                  disabled: submission,
                  class: "my-0",
                },
                row: entry.options?.row,
              };
            },
            methods: {
              checkVal() {
                checkValidation(this);
              },
            },
          });

        case "date":
          return Vue.extend({
            template: `
          <v-container>
          <div class="answer-wrap" :class="{'required':entry.required}">
              <span class="flag" v-if="!submission && entry.required">* {{$t("t_required")}}</span>
              <v-date-picker @change="checkVal" :show-current="false" ref="input" v-bind="datePickerOpts" v-model="date"></v-date-picker>
          </div>
          </v-container>
          `,
            components: { VContainer, VDatePicker, VMenu, VTextField },
            data() {
              return {
                entry,
                menu: false,
                submission,
                date: null,
                menuOpts: {
                  closeOnContentClick: false,
                  transition: "scale-transition",
                  nudgeTop: 20,
                  offsetY: true,
                  maxWidth: "290px",
                  minWidth: "auto",
                },
                textFieldOpts: {
                  outlined: true,
                  label: this.$t("c_formviewer.t_date"),
                  prependIcon: "mdi-calendar",
                  readonly: true,
                  disabled: preview || submission,
                },
                datePickerOpts: {
                  class: "ma-0",
                  locale: this.$store.state?.settings?.lang,
                  disabled: preview || submission,
                },
              };
            },
            computed: {
              dateFormatted() {
                if (!this.date) return null;
                const [year, month, day] = this.date.split("-");
                return `${month}/${day}/${year}`;
              },
            },
            watch: {
              date(val) {
                this.entry.value = new Date(val).toISOString();
              },
            },
            methods: {
              checkVal() {
                checkValidation(this);
              },
            },
            mounted() {
              if (this.entry.value) {
                this.date = new Date(this.entry.value).toISOString().substr(0, 10);
              }
            },
          });

        case "time":
          return Vue.extend({
            template: `
          <v-container>
          <div class="answer-wrap" :class="{'required':entry.required}">
            <span class="flag" v-if="!submission && entry.required">* {{$t("t_required")}}</span>
            <v-time-picker @input="checkVal" ref="input" v-bind="timePickerOpts" v-model="entry.value"></v-time-picker>
          </div>
          </v-container>
          `,
            components: { VContainer, VMenu, VTextField, VTimePicker },
            data() {
              return {
                entry,
                menu: false,
                submission,
                menuOpts: {
                  closeOnContentClick: false,
                  transition: "scale-transition",
                  nudgeTop: 20,
                  offsetY: true,
                  maxWidth: "290px",
                  minWidth: "auto",
                },
                textFieldOpts: {
                  outlined: true,
                  label: this.$t("c_formviewer.t_time"),
                  prependIcon: "mdi-clock-outline",
                  readonly: true,
                  disabled: preview || submission,
                },
                timePickerOpts: {
                  scrollable: true,
                  class: "ma-0",
                  format: this.$store.state?.settings?.timeFormat24Hour ? "24hr" : "ampm",
                  disabled: preview || submission,
                },
              };
            },
            computed: {
              timeFormatted() {
                if (!this.entry.value) return null;
                const [hour, min] = this.entry.value.split(":");
                return `${hour > 12 ? hour % 12 : hour}:${min} ${hour < 12 ? "AM" : "PM"}`;
              },
            },
            methods: {
              checkVal() {
                checkValidation(this);
              },
            },
          });

        case "signature":
          return Vue.extend({
            template: `
<div class="answer-wrap grey--text" :class="{'required':entry.required, 'text--darken-2': $vuetify.theme.light, 'text--lighten-2': $vuetify.theme.dark}">
  <span class="flag" v-if="!submission && entry.required">* {{$t("t_required")}}</span>
    <div>
        <vueSignature :ref="'signature-' + key" h="240px" :sigOption="options" v-model="entry.value" :disabled="submission || accepted" class="signature"></vueSignature>
        <div v-if="!submission">
            <div class="text-center" v-if="!entry.value">{{$t("c_formviewer.t_sign_above")}}</div>
            <v-alert v-if="fileSizeExceeds" class="my-3" dense type="error">Signature is too large. Please clear and simplify</v-alert>
            <div v-if="!submission" class="d-flex mt-3">
                <v-btn v-if="!entry.value" class="primary" @click="saveSignature()">{{$t("c_formviewer.t_accept_signature")}}</v-btn>
                <span v-else class="text-overline accepted">
                  <v-icon color="green darken-2" style="position:relative;top:-2px;" class="mr-0">mdi-check-bold</v-icon>
                  {{$t("c_formviewer.t_signature_accepted")}}
                </span>
                <v-spacer></v-spacer>
                <v-btn @click="clearSignature">{{$t("t_clear")}}</v-btn>
            </div>
        </div>
    </div>
</div>
                        `,
            components: { vueSignature, VBtn, VSpacer, VContainer, VIcon, VAlert },
            data() {
              return {
                entry,
                key,
                preview,
                submission,
                accepted: false,
                fileSizeExceeds: false,
                options: {
                  penColor: this.$vuetify.theme.dark ? "rgb(170,170,170)" : "rgb(100,100,100)",
                  backgroundColor: this.$vuetify.theme.dark ? "rgb(30,30,30)" : "rgb(255,255,255)",
                },
              };
            },
            watch: {
              "entry.value"(val) {
                if (val) {
                  this.getFromDataURL();
                }
              },
            },
            methods: {
              saveSignature() {
                if (this.$refs["signature-" + key].isEmpty()) {
                  this.$refs.input.$el.closest(".entry-section").classList.add("show-required");
                } else {
                  this.$refs["signature-" + key].$el.closest(".entry-section").classList.remove("show-required");
                  if (document.getElementsByClassName("show-required")?.length == 0) {
                    this.$parent.$refs["alert-check-required"]?.$el.classList.remove("show-alert");
                  }
                  var svg = this.$refs["signature-" + key].save("image/svg+xml");
                  const svgString = Buffer.from(svg, "base64");
                  const svgBlob = new Blob([svgString], { type: "image/svg+xml" });
                  const fileSize = svgBlob.size;
                  if (fileSize > 59999) {
                    this.fileSizeExceeds = true;
                    return;
                  }
                  this.entry.value = svg;
                  this.accepted = true;
                }
              },
              clearSignature() {
                this.accepted = false;
                this.fileSizeExceeds = false;
                this.$refs["signature-" + key].clear();
                this.entry.value = "";
              },
              getFromDataURL() {
                setTimeout(() => {
                  this.$refs["signature-" + key]?.fromDataURL(this.entry.value);
                }, 1000);
              },
            },
            mounted() {
              if (this.entry?.value) {
                this.getFromDataURL();
              }
            },
          });

        case "file":
          return Vue.extend({
            template: `
        <v-container>
          <div class="answer-wrap" :class="{'required':entry.required && !submission}">
            <span class="flag" v-if="!submission && entry.required">* {{$t("t_required")}}</span>
            <v-file-input
                v-if="!submission"
                v-bind="opts"
                v-model="entry.value"
                show-size
                class="picker"
                :label="$t('c_formviewer.t_select_a_file')"
                accept="image/*, application/pdf"
                ref="input"
                @change="checkVal"
            ></v-file-input>
            <div class="mt-1">
              <img v-if="previewUrl" :src="previewUrl" @click="imgDialog = true" style="cursor: pointer;max-height:100%;max-width:100%;" class="mt-6">
              <div v-if="downloadUrl" :class="{'mt-6 mb-2 ml-8':!submission}">
                <v-btn v-if="downloadUrl" :href="downloadUrl" target="_blank">{{downloadFile}}</v-btn>
              </div>
              <span v-if="submission && noFile">{{$t('c_formviewer.t_no_file')}}</span>
              <span v-if="submission && hasError">{{$t('c_formviewer.t_error_finding_file')}}</span>
              <div v-if="(entry.value || entry.file) && !submission">
                <v-btn :class="{'ml-8 mt-2':!previewUrl}" outlined x-small text color="primary" @click="confirmRemoveFile">{{$t('t_remove')}}</v-btn>
              </div>
            </div>
            <v-dialog v-model="imgDialog" width="660" overlay-opacity="0.8">
              <v-card>
                    <img class="ml-7 ma-3" width="600" :src="previewUrl">
                    <div style="display: flex">
                        <v-btn
                            color="primary"
                            text
                            @click="imgDialog = false"
                            class="ml-auto ma-2"
                        >{{$t('t_close')}}</v-btn>
                    </div>
                </v-card>
            </v-dialog>
          </div>
          <AppConfirmDialog ref="confirm" />
          </v-container>
          `,
            components: { VContainer, VFileInput, VDialog, VCard, VBtn, AppConfirmDialog },
            computed: {},
            data() {
              return {
                previewUrl: "",
                downloadUrl: "",
                noFile: false,
                hasError: false,
                refreshKey: false,
                imgDialog: false,
                entry,
                submission,
                submissionId,
                form,
                opts: {
                  rules,
                  hint: !(preview || submission)
                    ? "JPEG, PNG, GIF, SVG, PDF - " + this.$t("c_formviewer.t_max_size_10MB")
                    : null,
                  persistentHint: !(preview || submission),
                  disabled: preview || submission,
                  clearable: false,
                },
              };
            },
            methods: {
              async confirmRemoveFile() {
                if (
                  await this.$refs.confirm.open(
                    this.$t("c_formviewer.t_confirm"),
                    this.$t("c_formviewer.t_remove_file?"),
                  )
                ) {
                  this.removeFile();
                  this.checkVal();
                }
              },
              removeFile() {
                this.refreshKey = !this.refreshKey;
                this.entry.file = null;
                this.entry.value = null;
                this.previewUrl = "";
                this.downloadUrl = "";
              },
              checkVal() {
                checkValidation(this);
                this.getPreviewUrl();
              },
              async getPreviewUrl() {
                if (this.entry.value) {
                  if (this.entry.value.type !== "application/pdf") {
                    this.previewUrl = URL.createObjectURL(this.entry.value);
                  }
                } else if (this.entry.file) {
                  if (this.entry.file.includes("https://")) {
                    this.previewUrl = this.entry.file;
                  } else {
                    this.previewUrl = await this.downloadAsset();
                  }
                }
              },
              async downloadAsset() {
                const params = {
                  formSubmissionId: this.form.id,
                  key: this.entry.key,
                };
                try {
                  const r = await FormService.getFormSubmissionAsset(params);
                  const a = await AssetService.downloadAsset({ assetId: r.data.assetId, token: r.data.assetPublicId });
                  if (a.data.mimeType == "application/pdf") {
                    this.downloadFile = a.data.originalFileName;
                    this.downloadUrl = a.data.downloadUrl;
                  } else if (a.data.mimeType.includes("image")) {
                    return fetch(a.data.downloadUrl)
                      .then((response) => response.blob())
                      .then((blob) => {
                        return URL.createObjectURL(blob);
                      });
                  }
                } catch (e) {
                  this.hasError = true;
                }
              },
            },
            created() {
              if (this.entry.file) {
                this.getPreviewUrl();
              } else {
                this.noFile = true;
              }
              if (!this.entry.value) {
                this.entry.value = null;
              }
              if (!(preview || submission)) {
                this.opts.rules.push(
                  (v) => !v || v.size < 10e6 || this.$t("c_formviewer.t_val_max_size_less_than_10MB"),
                );
              }
            },
          });

        case "image":
          return Vue.extend({
            template: `
          <div>
            <div class="mb-2">{{entry.value}}</div>
            <img style="max-width:100%;max-height:100%;" v-if="entry.asset?.value" :src="assetUrl"></img>
          </div>
          `,
            components: { VTextarea, VImg, idb },
            data() {
              return {
                formId,
                assetUrl: null,
                entry,
                opts: {
                  outlined: true,
                  filled: true,
                  rows: 1,
                },
              };
            },
            methods: {
              async getAssetUrl() {
                if (this.entry.asset.value.url) {
                  this.assetUrl = this.entry.asset.value.url;
                } else {
                  this.assetUrl = await this.downloadAsset();
                }
              },

              async downloadAsset() {
                const params = { formId: this.formId, key: this.entry.key };
                const r = await FormService.getFormAsset(params);
                const assetPublicId = r.data?.assetPublicId;
                const assetCacheable = r.data?.assetCacheable;

                if (assetCacheable && assetPublicId) {
                  const s3Item = await idb.getS3Item(assetPublicId);
                  if (s3Item) {
                    return URL.createObjectURL(s3Item.value);
                  }
                }
                const a = await AssetService.downloadAsset({
                  assetId: r.data.assetId,
                  token: assetPublicId,
                });

                return fetch(a.data.downloadUrl)
                  .then((response) => response.blob())
                  .then((blob) => {
                    if (assetCacheable) {
                      idb.putS3Item(assetPublicId, blob);
                    }
                    return URL.createObjectURL(blob);
                  });
              },
            },
            mounted() {
              if (this.entry.asset?.value?.assetId) {
                this.getAssetUrl();
              }
            },
          });

        case "pdf":
          return Vue.extend({
            template: `
<div>
  <div class="mb-2">{{entry.value}}</div>
  <embed v-if="$platform == 'web' && pdfSupported && entry.asset?.value" :src="assetUrl" type="application/pdf" style="width:100%; height:100vh;" @error="pdfFailedToLoad">
  <vue-pdf-embed v-else-if="entry.asset?.value" :text-layer="true" ref="pdf" :source="assetUrl" :scale="2" :key="componentKey"/>
</div>
                        `,
            components: { VuePdfEmbed },
            watch: {
              "entry.asset.value.assetId"() {
                this.getAssetUrl();
              },
            },
            data() {
              return {
                formId,
                assetUrl: null,
                entry,
                opts: {
                  rules,
                  outlined: true,
                  filled: true,
                  rows: 1,
                },
                componentKey: 0,
                pdfSupported: false,
              };
            },
            methods: {
              handleResize() {
                this.componentKey += 1;
              },

              pdfFailedToLoad() {
                this.pdfSupported = false;
              },

              async getAssetUrl() {
                if (this.entry.asset.value.url) {
                  this.assetUrl = this.entry.asset.value.url;
                } else {
                  this.assetUrl = await this.downloadAsset();
                }
              },

              async downloadAsset() {
                const params = { formId: this.formId, key: this.entry.key };
                const r = await FormService.getFormAsset(params);
                const assetPublicId = r.data?.assetPublicId;
                const assetCacheable = r.data?.assetCacheable;

                if (assetCacheable && assetPublicId) {
                  const s3Item = await idb.getS3Item(assetPublicId);
                  if (s3Item) {
                    return URL.createObjectURL(s3Item.value);
                  }
                }
                const a = await AssetService.downloadAsset({
                  assetId: r.data.assetId,
                  token: assetPublicId,
                });

                return fetch(a.data.downloadUrl)
                  .then((response) => response.blob())
                  .then((blob) => {
                    if (assetCacheable) {
                      idb.putS3Item(assetPublicId, blob);
                    }
                    return URL.createObjectURL(blob);
                  });
              },
            },

            beforeDestroy() {
              window.removeEventListener("resize", this.handleResize);
            },

            mounted() {
              if (this.entry.asset?.value?.assetId) {
                this.getAssetUrl();
              }
              window.addEventListener("resize", this.handleResize);
            },
          });

        case "video":
          return Vue.extend({
            template: `
            <div class="video-wrap">
              <div class="mb-2">{{entry.value}}</div>
              <div v-if="videoUrl" class="video-iframe-wrap">
                <iframe :src="videoUrl" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
              </div>
              <div v-else-if="entry.asset?.value">
                <video class="video-player"
                  ref="videoPlayer"
                  :src="assetUrl"
                  controls
                  preload="metadata"
                  width="100%"
                  @error="reloadVideo"
                  @play="setPlaying"
                ></video>
                <v-btn x-large v-if="!isPlaying" class="btn-start" @click="startVideo">Start</v-btn>
              </div>
            </div>
            `,
            components: { VTextarea, VBtn },
            data() {
              return {
                formId,
                assetUrl: null,
                videoUrl: "",
                entry,
                opts: {
                  filled: true,
                  outlined: true,
                  rows: 1,
                },
                activeAsset: "",
                isPlaying: false,
              };
            },
            methods: {
              async getAssetUrl() {
                this.assetUrl = await this.downloadAsset();
              },
              loadVideoUrl(url) {
                url = this.checkYouTubeURL(url);
                this.videoUrl = url;
              },
              startVideo() {
                const videoElement = this.$refs.videoPlayer;
                videoElement.play();
                this.isPlaying = true;
              },
              setPlaying() {
                this.isPlaying = true;
              },
              async reloadVideo() {
                this.assetUrl = "";
                await this.$nextTick();
                await this.getAssetUrl();
                this.startVideo();
              },
              async downloadAsset() {
                const params = { formId: this.formId, key: this.entry.key };
                const r = await FormService.getFormAsset(params);
                const assetPublicId = r.data?.assetPublicId;
                const assetCacheable = r.data?.assetCacheable;

                if (assetCacheable && assetPublicId) {
                  const s3Item = await idb.getS3Item(assetPublicId); //
                  if (s3Item) {
                    return URL.createObjectURL(s3Item.value);
                  }
                }
                const a = await AssetService.downloadAsset({
                  assetId: r.data.assetId,
                  token: assetPublicId,
                });

                return a.data.downloadUrl;
              },
              checkYouTubeURL(url) {
                // Check if the URL is a youtu.be URL
                const match = url.match(/youtu\.be\/(.+)/);
                if (match) {
                  const videoID = match[1];
                  const embedURL = "https://www.youtube.com/embed/" + videoID;
                  return embedURL;
                }
                return url;
              },
            },
            mounted() {
              if (this.entry.asset?.value?.assetId) {
                if (this.entry.asset?.value.url) {
                  this.loadVideoUrl(this.entry.asset?.value.url);
                } else {
                  this.getAssetUrl();
                }
              }
            },
          });

        case "action":
          return Vue.extend({
            template: `
          <div>
            <div class="mb-2">
                <div>{{$t('c_formviewer.t_required_progress_update')}}:</div>
                <v-btn color="primary" v-bind="opts" class="mt-2" @click="sendNotification">{{$t('c_formviewer.t_submit_stop_check')}}</v-btn>
            </div>
            <v-dialog persistent v-model="actionDialog" max-width="420" content-class="action-dialog" style="background:#222;padding:15px">
              <v-card>
                  <v-card-title class="text-h6">
                      {{$t('c_formviewer.t_stop_check_has_been_submitted')}}
                  </v-card-title>
                  <br/>
                  <v-card-text>{{$t('c_formviewer.t_form_progress_has_been_submitted')}}.</v-card-text>
                  <br/><br/>
                  <v-card-actions>
                    <v-btn color="primary" @click="$router.go(-1)">{{$t('c_formviewer.t_back_to_forms')}}</v-btn>
                  </v-card-actions>
                </v-card>
            </v-dialog>
          </div>
          `,
            components: { VContainer, VTextField, VTextarea, VBtn, VDialog },
            data() {
              return {
                actionDialog: false,
                entry,
                opts: {
                  disabled: preview || submission,
                },
              };
            },
            methods: {
              async sendNotification() {
                if (this.entry.action?.formStopCheckId) {
                  const params = {
                    id: this.entry.action.formStopCheckId,
                  };
                  const r = await FormService.submitStopCheck(params);
                  if (r) {
                    this.actionDialog = true;
                  } else {
                    return;
                  }
                }
              },
            },
          });

        case "section":
          return Vue.extend({
            template: `
          <div>
            <div v-if="entry.content">
              <div :class="classes()" v-if="entry.content.title">{{entry.content.title}}</div>
              <div v-if="entry.content.description">{{entry.content.description}}</div>
            </div>
          </div>
          `,
            components: { VTextarea },
            data() {
              return {
                entry,
                optsTitle: {
                  filled: true,
                  outlined: true,
                  placeholder: "Section title",
                  hideDetails: true,
                  autoGrow: true,
                  rows: 1,
                },
                optsDescription: {
                  filled: true,
                  outlined: true,
                  placeholder: "Section description",
                  hideDetails: true,
                  autoGrow: true,
                  rows: 3,
                },
              };
            },
            methods: {
              classes() {
                return {
                  "font-weight-medium": true,
                  ...fontStyle(this.entry.ui.style),
                };
              },
            },
          });
        default:
          return;
      }
    },
    collectAnswers() {
      const answers = {};
      this.schema.filter((entry) => entry.answer).forEach((entry) => (answers[entry.key] = entry.answer.value));
      const files = this.schema.filter((entry) => entry.ui.el === "file");
      if (files.length) {
        answers.files = [];
        files.forEach((entry) => answers.files.push({ key: entry.key, value: entry.value }));
      }
      const sigs = this.schema.filter((entry) => entry.ui.el === "signature");
      if (sigs.length) {
        sigs.forEach((entry) => (answers[entry.key] = entry.value));
      }
      return answers;
    },
  },
};
</script>

<style lang="scss">
.select-dropdown {
  width: 100%;
  color: #fff;
  border: 1px solid #6f6f6f;
  background: #424242;
  padding: 8px;
  border-radius: 5px;
  -webkit-appearance: listbox !important;
}
.picker {
  width: 290px;
  padding: 0;
  margin: 0;
}
.answer-placeholder {
  border: 2px dashed #808080;
  height: 5rem;
  margin-top: 1rem;
}
.form-text-center {
  textarea {
    text-align: center;
  }
}
.action-dialog {
  background: #262626;
  padding: 30px;
  border: rgba(0, 0, 0, 0.5) 5px solid;
  border-radius: 5px;
}
.form-text-1 {
  font-size: 0.75rem;
}
.form-text-2 {
  font-size: 1rem;
}
.form-text-3 {
  font-size: 1.25rem;
}
.form-text-4 {
  font-size: 1.75rem;
}
.form-text-5 {
  font-size: 2.25rem;
}
.form-text-6 {
  font-size: 3.25rem;
}
.list-entries {
  margin-top: -25px;

  .drag-area:empty {
    padding-bottom: 70px;
    background: #4b4b4b;
    border-radius: 5px;
    border: 1px dashed #85daff;
    margin: 0 15px;
    //background: url("https://image.flaticon.com/icons/svg/60/60993.svg") 0 0 / 20px 30px repeat;
    background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="%232196F3" d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z"></path></svg>');
    //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%23740074' points='1600 160 0 460 0 350 1600 50'/%3E%3Cpolygon fill='%235f005f' points='1600 260 0 560 0 450 1600 150'/%3E%3Cpolygon fill='%234b004b' points='1600 360 0 660 0 550 1600 250'/%3E%3Cpolygon fill='%23360036' points='1600 460 0 760 0 650 1600 350'/%3E%3Cpolygon fill='%23220022' points='1600 800 0 800 0 750 1600 450'/%3E%3C/g%3E%3C/svg%3E");
    //url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2064%2064%22%3E%3Cpath%20d%3D%22M32.194%207.106c-13.689%200-24.826%2011.137-24.826%2024.826%200%2013.69%2011.137%2024.828%2024.826%2024.828S57.02%2045.622%2057.02%2031.932c.001-13.689-11.136-24.826-24.826-24.826zm13.717%2023.011v.001l-7.187%205.2%202.768%208.46c.044.132.067.273.067.417%200%20.739-.598%201.339-1.337%201.339-.294%200-.564-.094-.784-.253l-7.243-5.239-7.243%205.239c-.22.159-.49.253-.783.253-.74%200-1.339-.599-1.339-1.339%200-.145.024-.285.068-.417l2.769-8.46-7.187-5.2v-.001c-.336-.242-.554-.637-.554-1.083%200-.739.598-1.337%201.338-1.337h8.897l2.755-8.421c.168-.547.677-.945%201.28-.945.602%200%201.112.398%201.279.945l2.755%208.421h8.897c.739%200%201.338.598%201.338%201.337-.001.446-.219.841-.554%201.083z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    //background-size: cover;
    background-position: center center;
  }
  .sortable-ghost {
    padding: 15px 15px 15px 10px;
    margin: 0 15px;
    border-radius: 5px;
    .grabby {
      display: none;
    }
  }
}
.signature {
  canvas {
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  &.signature-required {
    canvas {
      border: 2px solid #b71c1c;
    }
  }
  .accepted {
    font-size: 14px !important;
    padding-top: 2px;
  }
}
.section-header {
  background: #9e772c;
  display: inline-block;
  margin-left: 15px;
  position: relative;
  top: 20px;
  z-index: 100;
}
.section-header-border {
  margin: 0px 15px;
  position: relative;
  top: 15px;
  border-top: 5px solid #9e772c;
  z-index: 100;
}

.theme--light {
  .entry-section {
    background: #eee;
    .answer-wrap {
      &.required {
        .flag {
          color: #b71c1c;
          background: #fff;
          border: 1px solid #ccc;
        }
      }
    }
  }
  .select-dropdown {
    background: #eee;
    color: #333;
  }
}

.alert-check-required {
  display: none !important;
  position: relative;
  top: -106px;
  border: none !important;
  &.show-alert {
    display: block !important;
  }
}

.entry-section {
  border-radius: 5px;
  margin: 30px 15px;
  background: #323232;
  padding: 30px 15px;
  position: relative;
  border: 2px solid transparent;
  .answer-wrap {
    width: 100%;
    .v-slider--horizontal {
      min-height: 32px;
      margin-left: 0px;
      margin-right: 0px;
    }
    &.required {
      .flag {
        position: absolute;
        top: -12px;
        right: 10px;
        font-size: 12px;
        color: #f3c8c8;
        background: #2b2b2b;
        letter-spacing: 1px;
        padding: 1px 4px;
        border-radius: 3px;
        border: 1px solid #1c1c1c;
      }
    }
  }
  &.show-required {
    border: 2px solid #b71c1c;
    .answer-wrap {
      &.required {
        .flag {
          background: rgb(250, 70, 70);
          color: #fff;
        }
      }
    }
  }
  &.section {
    border-radius: 0 0 5px 5px;
    margin-top: 15px;
  }
  .form-type-badge {
    position: absolute;
    left: -7px;
    top: -3px;
    padding: 0px 6px;
    background: #515151 !important;
    font-size: 9px;
    text-transform: uppercase;
    z-index: 200;
  }
  &.section .form-type-badge {
    display: none;
  }
  .grabby {
    position: absolute;
    top: -4px;
    left: 50%;
    margin: 0 -5px;
  }
  .text-wrap {
    margin: 15px;
    max-width: 300px;
  }
  .textfield-wrap {
    margin: 15px;
  }
  .select {
    .invalid {
      border: 2px solid #b71c1c;
    }
  }
  .comma-list:not(:empty):not(:last-child):after {
    content: ", ";
  }
  .v-slider--vertical {
    min-height: 100px;
  }
  .height-2 .v-slider--vertical {
    min-height: calc(100px + 50px);
  }
  .height-3 .v-slider--vertical {
    min-height: calc(100px + 100px);
  }
  .height-4 .v-slider--vertical {
    min-height: calc(100px + 150px);
  }
  .height-5 .v-slider--vertical {
    min-height: calc(100px + 200px);
  }
  .height-6 .v-slider--vertical {
    min-height: calc(100px + 250px);
  }
  .height-7 .v-slider--vertical {
    min-height: calc(100px + 300px);
  }
  .height-8 .v-slider--vertical {
    min-height: calc(100px + 350px);
  }
  .height-9 .v-slider--vertical {
    min-height: calc(100px + 400px);
  }
  .height-10 .v-slider--vertical {
    min-height: calc(100px + 450px);
  }
  .v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense) textarea {
    margin-top: 15px;
    margin-bottom: 12px;
  }
  .video-wrap {
    .video-player {
      position: relative;
    }

    .btn-start {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
  .video-iframe-wrap {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
.entry-section:last-of-type {
  //border-bottom: none;
}
.textarea-edit {
  .v-input__slot {
    min-height: 30px !important;
    padding-bottom: 5px !important;
  }
}
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}
.is-submission {
  .v-stepper__wrapper {
    height: unset !important;
  }
}
</style>
